
import { defineComponent } from 'vue';
import PageHeader from '../components/PageHeader.vue'
import Banner from '../components/Banner.vue'
import { getCorporate, ParsedItem } from '../lib/Contentful'

interface CorporateData {
  sections: ParsedItem[];
}

export default defineComponent({
  name: 'Corporate',
  components: {
    PageHeader,
    Banner
  },
  data(): CorporateData {
    return {
      sections: []
    }
  },
  async mounted() {
    const w = window as any;
    w.fbq('track', 'ViewContent');
    await this.getCorporate()
  },
  methods: {
    async getCorporate() {
      this.sections = await getCorporate()
    }
  }
});
